import 'maui/dist/styles/maui.css';

import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Loader, theme, Sidebar as MauiSidebar } from 'maui';
import { Provider, useDispatch } from 'react-redux'
import { BrowserRouter, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IUser } from 'findem-types';
import { init as initFullStory } from '@fullstory/browser';

// APIs
import { useGetCurrentUserQuery } from './services/users';

// Types
import { RootState } from './store';

// Utils
import { store } from './store'
import { appStateActions } from './reducers/appState';

// Styled + Components
import AppStyles, { MainWrapper } from './App.styled';
import LoggedOutSplash from './components/LoggedOutSplash';
import AppRoutes from './components/Routes';
import Sidebar from './components/Sidebar';

const App = () => {
  const dispatch = useDispatch();

  //--------------------------------------------------------------------------------
  // Store
  //--------------------------------------------------------------------------------

  const storeUser: IUser = useSelector(
    (state: RootState) => state.appState.currentUser
  );

  const sidebarCollapsed: boolean = useSelector(
    (state: RootState) => state.appState.sidebarCollapsed
  );

  //--------------------------------------------------------------------------------
  // APIs
  //--------------------------------------------------------------------------------

  const {
    data: currentUser,
    isLoading: isCurrentUserLoading,
  } = useGetCurrentUserQuery();

  //--------------------------------------------------------------------------------
  // Effects
  //--------------------------------------------------------------------------------

  useEffect(() => {
    if (currentUser) {
      dispatch(appStateActions.setCurrentUser(currentUser));
    }
  }, [currentUser]);

  if (isCurrentUserLoading) {
    return (
      <Loader $fullPage />
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <AppStyles />
      { !!currentUser && !!storeUser.uid ? (
          <BrowserRouter>
            <Sidebar />
            <MauiSidebar.MainWrapperWithSidebar
              $collapsed={sidebarCollapsed}
              id='main-wrapper'
            >
              <AppRoutes />
            </MauiSidebar.MainWrapperWithSidebar>
          </BrowserRouter>
        ) : (
          <LoggedOutSplash />
        )
      }
    </ThemeProvider>
  )
}
const LiftedApp = () => {
  initFullStory({ orgId: 'VB5PS', devMode: process.env?.NODE_ENV === 'development' });

  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}

export default LiftedApp;
