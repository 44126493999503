import { PreloadedState, combineReducers, configureStore } from '@reduxjs/toolkit';

import * as customReducers from './reducers';

import { emailsApi } from './services/emails';
import { htmlLibraryApi } from './services/html-library';
import { orgsApi } from './services/orgs';
import { usersApi } from './services/users';
import { userPreferencesApi } from './services/user-preferences';
import { workflowsApi } from './services/workflows';
import { interviewsApi } from './services/interviews';

const rootReducer = combineReducers({
  [emailsApi.reducerPath]: emailsApi.reducer,
  [htmlLibraryApi.reducerPath]: htmlLibraryApi.reducer,
  [orgsApi.reducerPath]: orgsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [userPreferencesApi.reducerPath]: userPreferencesApi.reducer,
  [workflowsApi.reducerPath]: workflowsApi.reducer,
  [interviewsApi.reducerPath]: interviewsApi.reducer,
  ...customReducers
});

// Add any RTK Query reducers here and we can sanitize them
// In order to have redux devtools run efficiently. It doesn't have
// To be all of them, any with large datasets such as search + campaigns.
const apiReducers: string[] = [
  emailsApi.reducerPath,
  htmlLibraryApi.reducerPath,
  orgsApi.reducerPath,
  usersApi.reducerPath,
  userPreferencesApi.reducerPath,
  workflowsApi.reducerPath,
  interviewsApi.reducerPath
];

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }).concat(
        emailsApi.middleware,
        htmlLibraryApi.middleware,
        orgsApi.middleware,
        usersApi.middleware,
        userPreferencesApi.middleware,
        workflowsApi.middleware,
        interviewsApi.middleware
      ),
    preloadedState,
    devTools: {
      actionSanitizer: (action: any) => {
        if (
          apiReducers.some((apiReducer: string) =>
            action.type.startsWith(`${apiReducer}/`)
          )
        ) {
          return {
            ...action,
            meta: '<<LONG_BLOB>>',
            payload: '<<LONG_BLOB>>'
          };
        }

        return action;
      },
      stateSanitizer: (state: any) => ({
        ...state,
        ...apiReducers.reduce(
          (sanitizedState: { [key: string]: string }, apiReducer: string) => {
            if (state[apiReducer]) {
              sanitizedState[apiReducer] = '<<LONG_BLOB>>';
            }

            return sanitizedState;
          },
          {}
        )
      })
    }
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export const store = setupStore();
