import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { IReferrer } from 'findem-types';
import { matchesUrl } from 'findem-helpers';

export interface ISaveMatchFeedbackParams {
  type: string;
  sub_type: string | undefined;
  prid: string;
  icpid: string;
  joid: string;
  state?: string;
  recruiter_assessment?: string;
  rejection_reason?: string | number;
  clear_campaign_cache?: boolean;
  profile_refresh_status?: string;
}

export const emailsApi = createApi({
  reducerPath: 'emails',
  baseQuery: fetchBaseQuery({
    baseUrl: `${matchesUrl}/hm/api/email_templates`
  }),
  tagTypes: ['GlobalReferrers', 'Profiles', 'CampaignReplies', 'CampaignStats', 'CustomDomains', 'LinkBranding', 'EmailNames'],
  keepUnusedDataFor: 300,
  endpoints: (build) => ({
    getGlobalReferrers: build.query<IReferrer[], string>({
      providesTags: ['GlobalReferrers'],
      query: (userId: string) => ({
        url: '',
        credentials: 'include',
        method: 'POST',
        body: {
          type: 'FetchGlobalReferrers',
          joid: userId
        }
      })
    }),

  })
});

export const {
  useGetGlobalReferrersQuery,
} = emailsApi;
