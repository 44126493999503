import { FetchBaseQueryMeta, fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { automationUrl } from 'findem-helpers';

// Types
import {
  ICreateWorkflowParams,
  IGetWorkflowsParams,
  IFlow,
  IUpdateWorkflowParams,
  IGetPluginsParams,
  IPluginDescriptor,
  IDeleteWorkflowParams,
  IFlowResource
} from '../types/workflows';

export const workflowsApi = createApi({
  reducerPath: 'workflows',
  tagTypes: ['workflows', 'workflow', 'workflowPlugins'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${automationUrl}/automation/api`
  }),
  endpoints: (build) => ({
    getWorkflows: build.query<IFlowResource[], IGetWorkflowsParams>({
      providesTags: (_r, _e, { accountId, workflowId }) => workflowId
        ? [{ type: 'workflow', workflowId }]
        : [{ type: 'workflows', accountId }],
      query: (params) => ({
        url: `/flows/${params.workflowId ?? ''}`,
        params: {
          // @TODO - cache breaker - talk to Srini
          r: new Date().getTime(),
        },
        credentials: 'include',
        method: 'GET',
      }),
    }),

    createWorkflow: build.mutation<IFlowResource, ICreateWorkflowParams>({
      invalidatesTags: (_r, _e, { accountId }) => (
        [{ type: 'workflows', accountId }]
      ),
      query: (params) => ({
        url: `/flows`,
        credentials: 'include',
        method: 'POST',
        body: {
          payload: {
            account_id: params.accountId,
            resource_name: params.flowName,
            resource_description: params.description,
            resource_tags: params.tags,
            flow: params.flow ?? {steps: [], trigger: {}},
          }
        }
      })
    }),

    updateWorkflow: build.mutation<unknown, IFlowResource>({
      query: (params) => {

        const { resource_id, ...payload} = params;

        return {
          url: `/flows/`,
          credentials: 'include',
          method: 'PUT',
          body: {
            resource_id,
            payload: {
              resource_name: payload.resource_name,
              resource_description: payload.resource_description,
              resource_tags: payload.resource_tags,
              flow: payload.flow,
            }
          },
        }
      }
    }),

    deleteWorkflow: build.mutation<unknown, IDeleteWorkflowParams>({
      invalidatesTags: (_r, _e, { accountId }) => (
        [{ type: 'workflows', accountId }]
      ),
      query: (params) => ({
        url: `/flows`,
        credentials: 'include',
        method: 'DELETE',
        body: {
          resource_id: params.workflowId
        }
      })
    }),

    // Plugins

    getPlugins: build.query<IPluginDescriptor[], IGetPluginsParams>({
      providesTags: (_r, _e, { accountId }) => [{ type: 'workflowPlugins', accountId }],
      query: (params) => ({
        url: `/plugins`,
        params: {
        // @TODO - cache breaker - talk to Srini
        r: new Date().getTime(),
        },
        credentials: 'include',
        method: 'GET',
      }),
    })
  })
});

export const {
  useGetWorkflowsQuery,
  useCreateWorkflowMutation,
  useUpdateWorkflowMutation,
  useDeleteWorkflowMutation,

  // Plugins
  useGetPluginsQuery,
} = workflowsApi;
