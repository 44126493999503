import React, { Suspense} from 'react';
import { Routes, Route } from 'react-router-dom';
import { Loader } from 'maui';

// Styled + Components
const AccountSettings = React.lazy(() => import('../AccountSettings'));
const HtmlLibrary = React.lazy(() => import('../HtmlLibrary'));
const Workflows = React.lazy(() => import('../Workflows'));
const Workflow = React.lazy(() => import('../Workflow'));

const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader $fullPage />}>
      <Routes>
        <Route path='/' element={<AccountSettings />} />
        <Route path="/workflows" element={<Workflows />} />
        <Route path="/workflow/:workflowId" element={<Workflow />} />
        { (
            process.env.REACT_APP_DEPLOY_ENV === 'staging' ||
            process.env.REACT_APP_DEPLOY_ENV === 'preprod'
          ) &&
          <Route path="/html" element={<HtmlLibrary />} />
        }
      </Routes>
    </Suspense>
  )
}

export default AppRoutes;