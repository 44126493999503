import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { IUser, IUserList } from 'findem-types';
import { transformUserRes } from 'findem-helpers';

import {
  matchesUrl,
  pubUrl,
  searchUrl,
  settingsUrl
} from 'findem-helpers';
import { IUpdatePasswordParams, IUpdateUserParams } from '../types/users';

export const usersApi = createApi({
  reducerPath: 'users',
  tagTypes: ['currentUser', 'users', 'UserRoles'],
  baseQuery: fetchBaseQuery({
    baseUrl: matchesUrl
  }),
  endpoints: (build) => ({
    getCurrentUser: build.query<IUser, void>({
      providesTags: ['currentUser'],
      query: () => ({
        url: `/api/user_info`,
        credentials: 'include'
      }),
      transformResponse: transformUserRes,
    }),

    getAllUsers: build.query<IUserList[], void>({
      query: () => ({ url: `/hm/api/users`, credentials: 'include' }),
    }),

    getUser: build.query<IUser, string>({
      query: (id) => ({
        url: `${pubUrl}/pub/api/user_info`,
        credentials: 'include',
        method: 'POST',
        body: {
          id
        }
      }),
    }),

    updateUser: build.mutation<IUser, IUpdateUserParams>({
      invalidatesTags: ['currentUser'],
      query: (params) => ({
        url: `api/user_info`,
        credentials: 'include',
        method: 'POST',
        body: {
          name: params.name,
          company_name: params.companyName,
          company_website: params.companyWebsite,
          role: params.role,
          phone_number: params.phone
        }
      })
    }),

    updatePassword: build.mutation<IUser, IUpdatePasswordParams>({
      query: (params) => ({
        url: `api/passwordReset`,
        credentials: 'include',
        method: 'POST',
        body: {
          old_password: params.oldPassword,
          new_password: params.newPassword,
        }
      })
    })

  })
});

export const {
  useGetCurrentUserQuery,
  useGetAllUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdatePasswordMutation,
} = usersApi;
