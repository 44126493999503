import { IUser } from 'findem-types';

export const LOGGED_OUT_USER: IUser = {
  company: '',
  domain: '',
  email: '',
  name: '',
  prid: '',
  roles: [],
  uid: '',
  loggedOut: true,
  account_creation: '',
  accountId: ''
};
