import { Icon } from 'maui';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Sidebar as UISidebar } from 'findem-ui-catalog';
import { IUser } from 'findem-types';

// Types
import { RootState } from '../../store';

// Utils
import { appStateActions } from '../../reducers/appState';

const sidebarMenuItems = [
  {
    key: 'workflows',
    label: <Link to='/workflows'>Workflow Automation</Link>,
    icon: <Icon icon='smart_toy' />
  }
]
const Sidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  //--------------------------------------------------------------------------------
  // Store
  //--------------------------------------------------------------------------------

  const currentUser: IUser = useSelector(
    (state: RootState) => state.appState.currentUser
  );

  const sidebarCollapsed: boolean = useSelector(
    (state: RootState) => state.appState.sidebarCollapsed
  );

  //--------------------------------------------------------------------------------
  // Callbacks
  //--------------------------------------------------------------------------------

  const handleCollapse = useCallback((state: boolean) => {
    dispatch(appStateActions.setSidebarCollapsed(state));
  }, []);

  const handleCustomPathNameLogic = useCallback((pathName: string) => {
    if (pathName === '') {
      return 'dashboard';
    } else if (pathName.startsWith('workflow')) {
      return 'workflows';
    }

    return pathName;
  }, [location]);

  return (
    <UISidebar
      currentUser={currentUser}
      collapsed={sidebarCollapsed}
      onCollapseChange={handleCollapse}
      consumerApp='account'
    />
  )
}

export default Sidebar;