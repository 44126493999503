import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { IOrg } from 'findem-types';
import { sourcingUrl } from 'findem-helpers';

export const orgsApi = createApi({
  reducerPath: 'orgs',
  baseQuery: fetchBaseQuery({
    baseUrl: sourcingUrl
  }),
  endpoints: (build) => ({
    getOrgs: build.query<IOrg[], void | null>({
      query: () => ({
        url: '/api/orgs/v2',
        credentials: 'include',
        method: 'GET'
      })
    })
  })
});

export const { useGetOrgsQuery } = orgsApi;
