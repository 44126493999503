import { Button, Result } from 'maui';
import React, { Component } from 'react';

import { matchesUrl } from 'findem-helpers';

const LoggedOutSplash = () => (
  <Result
    title="Please Login"
    subTitle="Sorry, you must be signed in to access this page."
    extra={
      <Button
        type='primary'
        size='large'
        href={`${matchesUrl}/auth/login`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Login Here
      </Button>
    }
  />
);

export default LoggedOutSplash;
