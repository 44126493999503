import { Node } from 'reactflow';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IAvailableParams, IFlow, IFlowResource, IStep, IStepIdCount, ITrigger } from '../types/workflows';

export interface IWorkflowState {
  isCreateNew: boolean;

  workflow?: IFlowResource;

  // ID Management
  stepIdCount: IStepIdCount;

  // Parameter Management
  availableParams: IAvailableParams;

  // React Flow
  nodes?: Node[],

  // UI States
  isPanelClick: boolean;
  isPausePan: boolean;
  viewNode?: string;
  highlightNode?: string;
}

export const initialState: IWorkflowState = {
  isCreateNew: false,
  workflow: undefined,

  stepIdCount: {},

  availableParams: {},

  isPanelClick: false,
  isPausePan: false,
};

const workflowState = createSlice({
  name: 'workflowState',
  initialState,
  reducers: {
    reset: () => initialState,

    // New Workflows
    setIsCreateNew(state: IWorkflowState, action: PayloadAction<boolean>) {
      state.isCreateNew = action.payload;
    },

    // Workflows
    setWorkflow(state: IWorkflowState, action: PayloadAction<IFlowResource | undefined>) {
      state.workflow = action.payload;
    },

    updateSteps: (state: IWorkflowState, action: PayloadAction<IStep[]>) => ({
      ...state,
      ...(state.workflow ? {
        workflow: {
          ...state.workflow,
          flow: {
            ...state.workflow.flow,
            steps: action.payload
          }
        }
      } : {}),
    }),

    updateTrigger: (state: IWorkflowState, action: PayloadAction<ITrigger | undefined>) => ({
      ...state,
      ...(state.workflow ? {
        workflow: {
          ...state.workflow,
          flow: {
            ...state.workflow.flow,
            trigger: action.payload
          }
        }
      } : {}),
    }),

    // ID Management
    setStepIdCount(state: IWorkflowState, action: PayloadAction<IStepIdCount>) {
      state.stepIdCount = action.payload;
    },

    // Available Params
    setAvailableParams(state: IWorkflowState, action: PayloadAction<IAvailableParams>) {
      state.availableParams = action.payload;
    },

    // React Flow
    setNodes(state: IWorkflowState, action: PayloadAction<Node[] | undefined>) {
      state.nodes = action.payload;
    },

    // UI States
    setIsPanelClick(state: IWorkflowState, action: PayloadAction<boolean>) {
      state.isPanelClick = action.payload;
    },

    setIsPausePan(state: IWorkflowState, action: PayloadAction<boolean>) {
      state.isPausePan = action.payload;
    },

    setViewNode(state: IWorkflowState, action: PayloadAction<string | undefined>) {
      state.viewNode = action.payload;
    },

    setHighlightNode(state: IWorkflowState, action: PayloadAction<string | undefined>) {
      state.highlightNode = action.payload;
    }
  }
});

export const workflowStateActions = workflowState.actions;

export default workflowState.reducer;
