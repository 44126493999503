import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';

import { interviewUrl } from 'findem-helpers';
import { IInterviewer, IInterviewerGCalAuthLinkParams, IInterviewerGCalAuthLinkResp, IInterviewerParams, IInterviewersInfoResp, IInterviewersResp } from '../types/interviews';

export const interviewsApi = createApi({
  reducerPath: 'interviews',
  baseQuery: fetchBaseQuery({
    baseUrl: interviewUrl
  }),
  tagTypes: ['getInterviewers', 'getInterviewer'],
  endpoints: (build) => ({
    getInterviewers: build.query<IInterviewersInfoResp[], string>({
      providesTags: ['getInterviewers'],
      query: (account_id: string) => ({
        url: `${interviewUrl}/interview/api/interviewer/account?account_id=${account_id}`,
        credentials: 'include',
        method: 'GET'
      }),
      transformResponse: (response: {interviewer_info: IInterviewersInfoResp[]}) => response.interviewer_info
    }),
    getInterviewer: build.query<IInterviewer, string>({
      providesTags: ['getInterviewer'],
      query: (teamId: string) => ({
        url: `${interviewUrl}/interview/api/interviewer${teamId ? `?teamId=${teamId}` : ''}`,
        credentials: 'include',
        method: 'GET'
      }),
      transformResponse: (response: IInterviewer) => response
    }),
    createOrUpdateInterviewer: build.mutation<IInterviewer, IInterviewerParams>({
      query: (team: IInterviewerParams) => ({
        url: `${interviewUrl}/interview/api/interviewer`,
        credentials: 'include',
        method: 'PUT',
        body: team
      }),
      transformResponse: (response: IInterviewer) => response
    }),
    createGCalInviteLink: build.mutation<IInterviewerGCalAuthLinkResp, IInterviewerGCalAuthLinkParams>({
      query: (params: IInterviewerGCalAuthLinkParams) => ({
        url: `${interviewUrl}/interview/api/interviewer/oauth`,
        credentials: 'include',
        method: 'POST',
        body: params
      }),
      transformResponse: (response: IInterviewerGCalAuthLinkResp) => response
    }),
  })
});

export const {
  useGetInterviewersQuery,
  useGetInterviewerQuery,
  useCreateOrUpdateInterviewerMutation,
  useCreateGCalInviteLinkMutation
} = interviewsApi;
