import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IUser } from 'findem-types';

// Utils
import { LOGGED_OUT_USER } from '../constants/users';

export interface IAppState {
  currentUser: IUser;

  sidebarCollapsed: boolean;
}

export const initialState: IAppState = {
  currentUser: LOGGED_OUT_USER,

  sidebarCollapsed: true,
};

const appState = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    reset: () => initialState,

    // User
    setCurrentUser(state: IAppState, action: PayloadAction<IUser>) {
      state.currentUser = action.payload;
    },

    // Sidebar
    setSidebarCollapsed(state: IAppState, action: PayloadAction<boolean>) {
      state.sidebarCollapsed = action.payload;
    },
  }
});

export const appStateActions = appState.actions;

export default appState.reducer;
